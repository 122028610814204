<template lang="pug">
.row.mx-0.input-card
  .d-none.d-xl-inline-block.left-section
    .row.mx-0
      ProgressCard(
        imageIcon="users",
        :currentStep="2",
        stepTitle="About Employees",
        @handleLeftClick="prevStep",
        @handleRightClick="updateForm")
      p The number of employees helps us determine the number of lives to be covered based on coverage type.
      p A rough age-bracket allows us to provide you with a more accurate estimate.
      p If you're unsure, you can leave it empty.
    img.pec-image(:src="require(`@/assets/images/pec-employees.svg`)")
  .d-inline-block.col.h-100.overflow-auto
    .py-2
    .container-pec-mobile.mx-auto
      .row.mx-0.mt-4.d-xl-none
        ProgressCard(
          imageIcon="users",
          :currentStep="2",
          stepTitle="About Employees",
          @handleLeftClick="prevStep",
          @handleRightClick="updateForm")
        p The number of employees helps us determine the number of lives to be covered based on coverage type.
        p A rough age-bracket allows us to provide you with a more accurate estimate.
        p If you're unsure, you can leave it empty.
      b-form.py-4(@submit.prevent="updateForm")
        vue-form-generator(
          :schema="resDef.schema",
          :model="prospectState",
          :options="resOptions", ref="formData")
        .row.col-12.col-xl-8.mx-auto
          n-button(
            variant="dark",
            block,
            buttonText="Continue",
            rightImageIcon="chevron-right",
            type="submit",
            @click="$refs.formData.validate()",
            @keydown.enter="$refs.formData.validate()")
</template>

<script>
import NButton from "@/components/NovaButton.vue";
import SelectCards from "@/components/SelectCards";
import resDefs from "./definitions";
import ProgressCard from "./components/ProgressCard.vue";
export default {
  components: {
    NButton,
    ProgressCard,
    SelectCards,
  },
  props: {
    prospectState: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const resName = "employees";
    return {
      resName,
      resDef: resDefs[resName],
      resOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
    };
  },
  methods: {
    async updateForm() {
      await this.$refs.formData.validate();
      if (this.$refs.formData.errors.length > 0) {
        this.$store.commit("addAlert", {
          variant: "danger",
          message:
            "Please enter valid details before continuing to the next step",
        });
      } else {
        this.$store.commit("clearAlerts");
        this.$emit("saveChanges", true);
        this.nextStep();
      }
    },
    nextStep() {
      this.$router.push({
        name: "pricingTable",
        params: { id: this.$route.params.id },
      });
    },
    prevStep() {
      this.$router.push({
        name: "workspace",
        params: { id: this.$route.params.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
</style>
